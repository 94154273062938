import { IconButton, IconButtonProps, Tooltip, styled } from '@mui/material';

interface Props extends IconButtonProps {
  expand: boolean;
}

const ExpandMoreButton = styled((props: Props) => {
  const { expand, ...other } = props;
  return (
    <Tooltip title={`Show ${expand ? 'less' : 'more'}`} placement="left">
      <IconButton {...other} />
    </Tooltip>
  );
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default ExpandMoreButton;
