import { ExpandMore, OpenInNew } from '@mui/icons-material';
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Collapse,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import ExpandMoreButton from './ExpandMoreButton';

interface Props {
  title: string;
  body: ReactNode;
  img?: string;
  externalUrl?: string;
  collapsible?: boolean;
  collapsedSize?: number;
  width?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  minMediaWidth?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  contain?: boolean;
  equalHeight?: boolean;
}

const CardItem = ({
  title,
  body,
  img,
  externalUrl,
  collapsible,
  collapsedSize = 362,
  width = 3,
  minMediaWidth = 1,
  contain,
  equalHeight,
}: Props) => {
  const theme = useTheme();
  const isMobileMedia = useMediaQuery(theme.breakpoints.down('sm'));
  const isTabletMedia = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLaptopMedia = useMediaQuery(theme.breakpoints.between('md', 'lg'));

  const [expanded, setExpanded] = useState(false);

  const calculatedWidth = useMemo(() => {
    if (isMobileMedia && 12 >= minMediaWidth) {
      return 12;
    }
    if (isTabletMedia && 6 >= minMediaWidth) {
      return 6;
    }
    if (isLaptopMedia && 4 >= minMediaWidth) {
      return 4;
    }

    return width;
  }, [isMobileMedia, isTabletMedia, isLaptopMedia, width, minMediaWidth]);

  const handleOpenInNewClick = useCallback(() => {
    window.open(externalUrl, '_blank');
  }, [externalUrl]);

  const handleExpandToggle = useCallback(() => {
    setExpanded((expanded) => !expanded);
  }, []);

  return (
    <Grid
      item
      xs={calculatedWidth}
      sx={{
        display: equalHeight ? 'flex' : undefined,
      }}
    >
      <Card
        variant="elevation"
        sx={{
          bgcolor: 'grey.100',
          display: equalHeight ? 'flex' : undefined,
          flexDirection: 'column',
          width: '100%',
        }}
      >
        {img && (
          <CardMedia
            src={img}
            component="img"
            title={title}
            height={256}
            sx={contain ? { objectFit: 'contain', p: 1 } : {}}
          />
        )}
        <CardContent sx={{ flex: 1 }}>
          {collapsible && (
            <Collapse
              in={expanded}
              timeout="auto"
              collapsedSize={collapsedSize}
            >
              <Typography gutterBottom variant="h4">
                {title}
              </Typography>
              <Typography variant="body1">{body}</Typography>
            </Collapse>
          )}
          {!collapsible && (
            <>
              <Typography gutterBottom variant="h4">
                {title}
              </Typography>
              <Typography variant="body1">{body}</Typography>
            </>
          )}
        </CardContent>
        {(externalUrl || collapsible) && (
          <CardActions disableSpacing>
            {externalUrl && (
              <Tooltip title="Open in new tab" placement="right">
                <IconButton
                  aria-label="Open in new"
                  onClick={handleOpenInNewClick}
                >
                  <OpenInNew />
                </IconButton>
              </Tooltip>
            )}
            {collapsible && (
              <ExpandMoreButton
                expand={expanded}
                onClick={handleExpandToggle}
                aria-expanded={expanded}
                aria-label={`Show ${expanded ? 'less' : 'more'}`}
              >
                <ExpandMore />
              </ExpandMoreButton>
            )}
          </CardActions>
        )}
      </Card>
    </Grid>
  );
};

export default CardItem;
