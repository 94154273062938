export const EDUCATION = (
  <>
    <b>Graduated:</b> 2016
    <br />
    <br />
    <b>Highschool:</b> Science and mathematics
    <br />
    <b>College:</b> Applied Science And Engineering (1y), Applied Informatics
    (graduated)
  </>
);

export const CODIFLY = (
  <>
    <b>Period:</b> 2017 - 2021
    <br />
    <br />
    Did my internship here and stayed because of everything that was still to
    learn in this young and booming startup. I've learned most of my skills
    here.
  </>
);

export const TEAMLEADER = (
  <>
    <b>Period: </b> 2021 - 2024
    <br />
    <br />
    Joined as a React Native consultant when it was still a scale-up and
    rejoined as a frontend React developer when the company grew in size.
  </>
);
