import { CssBaseline, ThemeProvider } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import About from './about';
import Experience from './experience';
import Navigation from './navigation';
import Projects from './projects';
import Technologies from './technologies';
import FloatingActionButton from './fab';
import theme from './theme';
import { Section } from './types';
import ContactDialog from './contact';
import AlertContainer from './alerts';
import TokenProvider from './tokens';

const sections = [
  Section.About,
  Section.Technologies,
  Section.Experience,
  Section.Projects,
];

const App = () => {
  const aboutRef = useRef<HTMLElement | null>(null);
  const technologiesRef = useRef<HTMLElement | null>(null);
  const experienceRef = useRef<HTMLElement | null>(null);
  const projectsRef = useRef<HTMLElement | null>(null);

  const [currentSection, setCurrentSection] = useState(Section.About);
  const [hasReachedBottomEnd, setHasReachedBottomEnd] = useState(false);
  const [isContactDialogOpen, setIsContactDialogOpen] = useState(false);

  const nextSection = useMemo(() => {
    const index = sections.indexOf(currentSection);

    if (index === sections.length - 1) {
      return null;
    }

    return sections[index + 1];
  }, [currentSection]);

  const navigate = useCallback((to: Section) => {
    ({
      [Section.About]: aboutRef,
      [Section.Experience]: experienceRef,
      [Section.Projects]: projectsRef,
      [Section.Technologies]: technologiesRef,
    })[to ?? Section.About]?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, []);

  const handleNavigate = useCallback(
    (to: Section) => {
      navigate(to);
    },
    [navigate],
  );

  const handleContactClick = useCallback(() => {
    setIsContactDialogOpen(true);
  }, []);

  const handleContactDialogClose = useCallback(() => {
    setIsContactDialogOpen(false);
  }, []);

  const handleFloatingActionButtonClick = useCallback(() => {
    navigate(nextSection ?? Section.About);
  }, [navigate, nextSection]);

  useEffect(() => {
    navigate(
      {
        '': Section.About,
        '#': Section.About,
        '#about': Section.About,
        '#experience': Section.Experience,
        '#projects': Section.Projects,
        '#technologies': Section.Technologies,
      }[window.location.hash] ?? Section.About,
    );
  }, [navigate]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + 16;
      const screenHeight = window.innerHeight;
      const documentHeight = document.body.offsetHeight;

      if (screenHeight + scrollPosition >= documentHeight) {
        setHasReachedBottomEnd(true);
      } else {
        setHasReachedBottomEnd(false);
      }

      const technologiesSection = technologiesRef.current;
      const experienceSection = experienceRef.current;
      const projectsSection = projectsRef.current;

      if (technologiesSection && experienceSection && projectsSection) {
        if (scrollPosition < technologiesSection.offsetTop) {
          setCurrentSection(Section.About);
        } else if (
          scrollPosition >= technologiesSection.offsetTop &&
          scrollPosition < experienceSection.offsetTop
        ) {
          setCurrentSection(Section.Technologies);
        } else if (
          scrollPosition >= experienceSection.offsetTop &&
          scrollPosition < projectsSection.offsetTop
        ) {
          setCurrentSection(Section.Experience);
        } else {
          setCurrentSection(Section.Projects);
        }
      }
    };

    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <TokenProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {nextSection && !hasReachedBottomEnd && (
          <FloatingActionButton
            nextSection={nextSection}
            onClick={handleFloatingActionButtonClick}
          />
        )}
        <About ref={aboutRef} />
        <Technologies ref={technologiesRef} />
        <Experience ref={experienceRef} />
        <Projects ref={projectsRef} />
        <AlertContainer />
        <Navigation
          onNavigate={handleNavigate}
          onContactClick={handleContactClick}
        />
        <ContactDialog
          isOpen={isContactDialogOpen}
          onClose={handleContactDialogClose}
        />
      </ThemeProvider>
    </TokenProvider>
  );
};

export default App;
