import {
  CheckCircleOutlined,
  ErrorOutlined,
  InfoOutlined,
  WarningOutlined,
} from '@mui/icons-material';
import { Alert, Box } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ADD_ALERT_EVENT } from './constants';
import { AlertType, Severity } from './types';

const AlertContainer = () => {
  const [alert, setAlert] = useState<AlertType | null>(null);

  const Icon = useMemo(() => {
    if (!alert) {
      return CheckCircleOutlined;
    }

    return {
      [Severity.Success]: CheckCircleOutlined,
      [Severity.Error]: ErrorOutlined,
      [Severity.Warning]: WarningOutlined,
      [Severity.Info]: InfoOutlined,
    }[alert.severity];
  }, [alert]);

  const handleClose = useCallback(() => {
    setAlert(null);
  }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    const handleAddAlert = (event: CustomEventInit) => {
      setAlert(event.detail as AlertType);
      timeout = setTimeout(() => {
        setAlert(null);
        clearTimeout(timeout);
      }, 3000);
    };

    document.addEventListener(ADD_ALERT_EVENT, handleAddAlert);

    return () => {
      document.removeEventListener(ADD_ALERT_EVENT, handleAddAlert);
    };
  }, []);

  if (!alert) {
    return <></>;
  }

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      right={0}
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      paddingY={12}
      paddingX={4}
    >
      <Alert
        icon={<Icon fontSize="inherit" />}
        severity={alert.severity}
        variant="standard"
        onClose={handleClose}
      >
        {alert.message}
      </Alert>
    </Box>
  );
};

export default AlertContainer;
