import { Box, Container, Grid, Typography } from '@mui/material';
import { forwardRef } from 'react';

import CyclistIcon from '../shared/media/cyclist.svg';
import ShoppingCartIcon from '../shared/media/shoppingcart.svg';
import TeamleaderImage from '../shared/media/teamleader.jpg';
import SkedifyImage from '../shared/media/skedify.png';
import DiceImage from '../shared/media/dice.jpg';
import UserIcon from '../shared/media/user.svg';
import CardItem from '../shared/components/CardItem';
import {
  CASINO_PROGRESSIVE_WEB_APP,
  CONTACT_FORM_SCHEDULING_SYSTEM,
  CYCLING_GAME,
  SOCIAL_SHOPPING_CART_APP,
  TEAMLEADER_FOCUS_WEB_APP,
  TEAMLEADER_MOBILE_APP,
  USER_MANAGEMENT_SYSTEM,
} from './content';

const Projects = forwardRef((_, ref) => (
  <Box bgcolor="grey.200">
    <Container maxWidth="lg">
      <Box
        ref={ref}
        component="section"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        paddingY={8}
      >
        <Typography marginBottom={8} variant="h3">
          Projects
        </Typography>
        <Grid container spacing={3}>
          <CardItem
            collapsible
            collapsedSize={240}
            contain
            width={4}
            img={CyclistIcon}
            title="Cycling Game"
            body={CYCLING_GAME}
          />
          <CardItem
            collapsible
            collapsedSize={240}
            contain
            width={4}
            img={ShoppingCartIcon}
            title="Social Shopping Cart App"
            body={SOCIAL_SHOPPING_CART_APP}
          />
          <CardItem
            collapsible
            collapsedSize={240}
            width={4}
            img={TeamleaderImage}
            title="Teamleader Mobile App"
            body={TEAMLEADER_MOBILE_APP}
          />
          <CardItem
            collapsible
            collapsedSize={240}
            contain
            width={4}
            img={SkedifyImage}
            title="Contact Form Scheduling System"
            body={CONTACT_FORM_SCHEDULING_SYSTEM}
          />
          <CardItem
            collapsible
            collapsedSize={240}
            width={4}
            img={DiceImage}
            title="Casino Progressive Web App"
            body={CASINO_PROGRESSIVE_WEB_APP}
          />
          <CardItem
            collapsible
            collapsedSize={240}
            width={4}
            contain
            img={UserIcon}
            title="User Management System"
            body={USER_MANAGEMENT_SYSTEM}
          />
          <CardItem
            collapsible
            collapsedSize={240}
            width={4}
            img={TeamleaderImage}
            title="Teamleader Focus Web App"
            body={TEAMLEADER_FOCUS_WEB_APP}
          />
        </Grid>
      </Box>
    </Container>
  </Box>
));

export default Projects;
