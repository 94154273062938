import { ReactNode, useEffect, useState } from 'react';

import { getToken } from './service';
import TokenContext from './context';

interface Props {
  children: ReactNode;
}

const TokenProvider = ({ children }: Props) => {
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const fetchedToken = await getToken();
        setToken(fetchedToken);
      } catch {}
    })();
  }, []);

  return (
    <TokenContext.Provider value={token}>{children}</TokenContext.Provider>
  );
};

export default TokenProvider;
