import { createTheme } from '@mui/material';

import { Color } from './types';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: Color.Primary,
    },
    secondary: {
      main: Color.Secondary,
    },
  },
});

export default theme;
