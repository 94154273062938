import {
  AppBar,
  Badge,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Mail, Menu as MenuIcon, Monitor } from '@mui/icons-material';
import { MouseEventHandler, useCallback, useState } from 'react';

import { Section } from '../types';
import Item from './Item';

interface Props {
  onNavigate: (to: Section) => void;
  onContactClick: () => void;
}

const Navigation = ({ onNavigate, onContactClick }: Props) => {
  const theme = useTheme();
  const isMobileMedia = useMediaQuery(theme.breakpoints.down('sm'));

  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const handleMenuClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      setMenuAnchor(event.currentTarget);
    },
    [],
  );

  const handleMenuClose = useCallback(() => {
    setMenuAnchor(null);
  }, []);

  const handleMenuItemClick = useCallback(
    (section: Section) => {
      handleMenuClose();
      onNavigate(section);
    },
    [handleMenuClose, onNavigate],
  );

  const handleContactMenuItemClick = useCallback(() => {
    handleMenuClose();
    onContactClick();
  }, [handleMenuClose, onContactClick]);

  return (
    <AppBar component="nav" position="fixed" color="primary">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingX={4}
        paddingY={2}
      >
        <Badge color="success" variant="dot">
          <Tooltip title="Looking for opportunities" placement="right">
            <Monitor />
          </Tooltip>
        </Badge>
        {isMobileMedia && (
          <Box>
            <Tooltip title="Menu" placement="left">
              <IconButton
                aria-label="Open menu"
                color="secondary"
                size="small"
                onClick={handleMenuClick}
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
            {menuAnchor && (
              <Menu
                open={Boolean(menuAnchor)}
                anchorEl={menuAnchor}
                onClose={handleMenuClose}
                onClick={handleMenuClose}
              >
                <Item
                  menuItem
                  to={Section.About}
                  label="About"
                  href="#about"
                  onClick={handleMenuItemClick}
                />
                <Item
                  menuItem
                  to={Section.Technologies}
                  label="Technologies"
                  href="#technologies"
                  onClick={handleMenuItemClick}
                />
                <Item
                  menuItem
                  to={Section.Experience}
                  label="Experience"
                  href="#experience"
                  onClick={handleMenuItemClick}
                />
                <Item
                  menuItem
                  to={Section.Projects}
                  label="Projects"
                  href="#projects"
                  onClick={handleMenuItemClick}
                />
                <MenuItem onClick={handleContactMenuItemClick}>
                  <Button variant="outlined" color="primary" size="small">
                    <Mail sx={{ mr: 1 }} />
                    Contact
                  </Button>
                </MenuItem>
              </Menu>
            )}
          </Box>
        )}
        {!isMobileMedia && (
          <Box display="flex" gap={2} alignItems="center">
            <Item
              to={Section.About}
              label="About"
              href="#about"
              onClick={onNavigate}
            />
            <Item
              to={Section.Technologies}
              label="Technologies"
              href="#technologies"
              onClick={onNavigate}
            />
            <Item
              to={Section.Experience}
              label="Experience"
              href="#experience"
              onClick={onNavigate}
            />
            <Item
              to={Section.Projects}
              label="Projects"
              href="#projects"
              onClick={onNavigate}
            />
            <Button
              variant="outlined"
              color="secondary"
              onClick={onContactClick}
              size="small"
            >
              <Mail sx={{ mr: 1 }} />
              Contact
            </Button>
          </Box>
        )}
      </Box>
    </AppBar>
  );
};

export default Navigation;
