import { Link, MenuItem } from '@mui/material';
import { useCallback } from 'react';

import { Section } from '../types';

interface Props {
  to: Section;
  label: string;
  href: string;
  onClick: (to: Section) => void;
  menuItem?: boolean;
}

const Item = ({ to, label, href, onClick, menuItem }: Props) => {
  const handleClick = useCallback(() => {
    if (menuItem) {
      window.location.hash = href;
    }

    onClick(to);
  }, [onClick, to, href, menuItem]);

  if (menuItem) {
    return <MenuItem onClick={handleClick}>{label}</MenuItem>;
  }

  return (
    <Link href={href} onClick={handleClick} color="grey.200" underline="hover">
      {label}
    </Link>
  );
};

export default Item;
