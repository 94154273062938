const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmail = (email: string) => {
  if (!email) {
    return 'Email address is required';
  }

  if (!EMAIL_REGEX.test(email)) {
    return 'Email address is invalid';
  }
};

export const validateBody = (body: string) => {
  if (!body) {
    return 'Message is required';
  }
};
