export const getToken = async (): Promise<string> => {
  const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/token`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const result = await response.json();

  return result['csrf_token'];
};
