import { Box, Container, Grid, Typography } from '@mui/material';
import { forwardRef } from 'react';

import HogentImage from '../shared/media/hogent.jpg';
import CodiflyImage from '../shared/media/codifly.jpg';
import TeamleaderImage from '../shared/media/teamleader.jpg';
import CardItem from '../shared/components/CardItem';
import { CODIFLY, EDUCATION, TEAMLEADER } from './content';

const Experience = forwardRef((_, ref) => (
  <Box bgcolor="grey.100">
    <Container maxWidth="lg">
      <Box
        ref={ref}
        component="section"
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
        paddingY={8}
      >
        <Typography marginBottom={8} variant="h3">
          Experience
        </Typography>
        <Grid container alignItems="stretch" spacing={3}>
          <CardItem
            equalHeight
            width={4}
            img={HogentImage}
            title="Education"
            body={EDUCATION}
            externalUrl="https://www.hogent.be/"
          />
          <CardItem
            equalHeight
            width={4}
            img={CodiflyImage}
            title="Consultant @ Codifly"
            body={CODIFLY}
            externalUrl="https://codifly.be"
          />
          <CardItem
            equalHeight
            width={4}
            img={TeamleaderImage}
            title="In-house engineer @ Teamleader"
            body={TEAMLEADER}
            externalUrl="https://www.teamleader.eu/"
          />
        </Grid>
      </Box>
    </Container>
  </Box>
));

export default Experience;
