export enum Severity {
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
}

export interface AlertType {
  severity: Severity;
  message: string;
}
