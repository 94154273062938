import { ArrowDownward } from '@mui/icons-material';
import { Box, Fab } from '@mui/material';

import { Section } from '../types';

interface Props {
  nextSection: Section;
  onClick: () => void;
}

const FloatingActionButton = ({ nextSection, onClick }: Props) => (
  <Box
    position="fixed"
    bottom={0}
    left={0}
    right={0}
    display="flex"
    justifyContent="center"
    alignItems="center"
    paddingY={4}
  >
    <Fab variant="extended" color="primary" size="small" onClick={onClick}>
      <ArrowDownward sx={{ mr: 1 }} />
      to {nextSection}
    </Fab>
  </Box>
);

export default FloatingActionButton;
