export const CYCLING_GAME = (
  <>
    This was my first project, I had to build six screens in a React Native app
    varying from a race overview to a detail page with an active countdown for
    when the next race in Belgium would be.
    <br />
    <br />
    There was a Restful API provided and the application made use of a Redux
    store to cache the API data.
  </>
);

export const SOCIAL_SHOPPING_CART_APP = (
  <>
    This was for a big supermarket chain in Belgium. A development team was set
    up in the city of Antwerp to create a React Native app providing
    grocery-related services with a social aspect.
    <br />
    <br />
    While I also contributed to the app, I was mainly responsible for the
    GraphQL backend. It was a system that took data from various NodeJS
    microservices and combined them into an "ask what you need" interface. I
    also worked on the microservices.
  </>
);

export const TEAMLEADER_MOBILE_APP = (
  <>
    My first touchpoint with Teamleader. While I was still a consultant, I led
    an in-house development team to re-invent the mobile app. We worked towards
    a release deadline and introduced fun features like the business card
    scanner, work orders on the go and timer functionality for their time
    tracking module.
    <br />
    <br />
    The app was also written in React Native and we used the Teamleader API with
    a client Redux store to implement our features.
  </>
);

export const CONTACT_FORM_SCHEDULING_SYSTEM = (
  <>
    After my time at Teamleader, I worked for Skedify. Here we had to set up a
    new system for a big client for them to schedule appointments with their
    clients. The system had to be highly customizable in content but also in
    theme. This was to make it scalable to other future clients.
    <br />
    <br />
    The work consisted of a content management platform, a backend and the
    resulting client-side form to be filled in. The frontend was written in
    React and we created a strongly typed NodeJS backend using TypeScript and
    TypeORM.
  </>
);

export const CASINO_PROGRESSIVE_WEB_APP = (
  <>
    As a consultant, I also worked for a gambling company. This was a rather
    different experience. Since I was still working for Codifly, I decided to
    take on the project. They were working on a new Single Page Application for
    the actual gaming platform and were nearing the release date.
    <br />
    <br />
    The project itself was not in good shape at the time when I started
    contributing. It was my job to add the necessary things to make it a
    Progressive Web App and to help fix and release the product. In the end, we
    managed to roll it out.
  </>
);

export const USER_MANAGEMENT_SYSTEM = (
  <>
    At the same gambling company, I also worked on the user management system. I
    led the frontend team there as we worked on widgets to show the Belgian
    Gambling Commission status and other important player information. Users
    could be temporarily suspended or blocked from access based on official
    data. Users also could suspend their accounts to prevent further loss.
    <br />
    <br />
    The app had a dashboard with multiple widgets where data could be shown and
    altered. Here, we also used GraphQL to communicate with the backend and
    Apollo to fetch and cache the data.
  </>
);

export const TEAMLEADER_FOCUS_WEB_APP = (
  <>
    After working for Codifly for a couple of years, I decided to join a company
    I've worked for as a consultant. Going back to Teamleader, which I've grown
    to love, felt like the right decision. They moved away from Redux to React
    Query for client-side data management and changed everything in the React
    code to become hook-based.
    <br />
    <br />
    When I started we worked on some CRM-related features such as global search
    functionality using Elastic Search and the rework of the entire "Companies
    Overview" page with updated search and filtering. We also had a technical
    year, followed by moving to the financial domain with Quotations and
    Invoices.
  </>
);
