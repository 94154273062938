import { Box, Container, Grid, Typography } from '@mui/material';
import { forwardRef } from 'react';

import ReactLogo from '../shared/media/react.svg';
import NodeJSLogo from '../shared/media/nodejs.svg';
import GraphQLLogo from '../shared/media/graphql.svg';
import AngularLogo from '../shared/media/angular.svg';
import KoaLogo from '../shared/media/koa.svg';
import ExpressLogo from '../shared/media/express.svg';
import JavaScriptLogo from '../shared/media/javascript.svg';
import TypeScriptLogo from '../shared/media/typescript.svg';
import CardItem from '../shared/components/CardItem';

const Technologies = forwardRef((_, ref) => (
  <Box bgcolor="grey.200">
    <Container maxWidth="lg">
      <Box
        ref={ref}
        component="section"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        paddingY={8}
      >
        <Typography marginBottom={8} variant="h3">
          Technologies
        </Typography>
        <Grid container alignItems="stretch" spacing={3}>
          <CardItem
            contain
            equalHeight
            title="NodeJS"
            body="Run JavaScript Everywhere"
            img={NodeJSLogo}
            externalUrl="https://nodejs.org/"
          />
          <CardItem
            contain
            equalHeight
            title="React"
            body="The library for web and native user interfaces"
            img={ReactLogo}
            externalUrl="https://react.dev/"
          />
          <CardItem
            contain
            equalHeight
            title="GraphQL"
            body="A query language for your API"
            img={GraphQLLogo}
            externalUrl="https://graphql.org/"
          />
          <CardItem
            contain
            equalHeight
            title="Angular"
            body="A web framework that empowers developers to build fast, reliable applications."
            img={AngularLogo}
            externalUrl="https://angular.dev/"
          />
          <CardItem
            contain
            equalHeight
            title="Koa"
            body="Next generation web framework for Node.js"
            img={KoaLogo}
            externalUrl="https://koajs.com/"
          />
          <CardItem
            contain
            equalHeight
            title="Express"
            body="Fast, unopinionated, minimalist web framework for Node.js"
            img={ExpressLogo}
            externalUrl="https://expressjs.com/"
          />
          <CardItem
            contain
            equalHeight
            title="JavaScript"
            body="A prototype-based, multi-paradigm, single-threaded, dynamic language, supporting object-oriented, imperative, and declarative (e.g. functional programming) styles."
            img={JavaScriptLogo}
          />
          <CardItem
            contain
            equalHeight
            title="TypeScript"
            body="A strongly typed programming language that builds on JavaScript, giving you better tooling at any scale."
            img={TypeScriptLogo}
            externalUrl="https://www.typescriptlang.org/"
          />
        </Grid>
      </Box>
    </Container>
  </Box>
));

export default Technologies;
