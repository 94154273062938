import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useCallback, useContext } from 'react';
import { Form, Field } from 'react-final-form';

import { Severity } from '../alerts/types';
import { addAlert } from '../alerts';
import TokenContext from '../tokens/context';
import { FormValues } from './types';
import { validateBody, validateEmail } from './validate';
import { sendMail } from './service';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const ContactDialog = ({ isOpen, onClose }: Props) => {
  const token = useContext(TokenContext);

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      try {
        await sendMail(values, token);

        addAlert({
          severity: Severity.Success,
          message: 'Your message was sent successfully.',
        });
      } catch (error) {
        addAlert({
          severity: Severity.Error,
          message: 'Something went wrong.',
        });
      } finally {
        onClose();
      }
    },
    [token, onClose],
  );

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>Contact me</DialogTitle>
      <DialogContent>
        <Form onSubmit={handleSubmit} initialValues={{ email: '', body: '' }}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
              <DialogContentText>
                Leave me a message and I'll get back to you.
              </DialogContentText>
              <Field name="email" validate={validateEmail}>
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    required
                    error={Boolean(meta.touched && meta.error)}
                    helperText={meta.touched && meta.error}
                    autoFocus
                    margin="normal"
                    id="email"
                    name="email"
                    label="Email address"
                    type="email"
                    fullWidth
                    placeholder="example@gmail.com"
                  />
                )}
              </Field>
              <Field name="body" validate={validateBody}>
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    required
                    error={Boolean(meta.touched && meta.error)}
                    helperText={meta.touched && meta.error}
                    multiline
                    minRows={5}
                    margin="normal"
                    id="body"
                    name="body"
                    label="Message"
                    type="text"
                    fullWidth
                    placeholder="Leave a message here..."
                  />
                )}
              </Field>
              <DialogActions>
                <Button onClick={onClose} color="primary" variant="outlined">
                  Cancel
                </Button>
                <Button type="submit" color="primary" variant="contained">
                  Send
                </Button>
              </DialogActions>
            </form>
          )}
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default ContactDialog;
