export enum Section {
  About = 'ABOUT',
  Technologies = 'TECHNOLOGIES',
  Experience = 'EXPERIENCE',
  Projects = 'PROJECTS',
}

export enum Color {
  Primary = '#3B3486',
  Secondary = '#FFE9B1',
}
