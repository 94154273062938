import { LinkedIn, SaveAlt } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Container,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { forwardRef, useCallback } from 'react';

import ProfileImage from '../shared/media/profile.jpg';
import CardItem from '../shared/components/CardItem';
import {
  CV_DOWNLOAD_FILE_NAME,
  CV_DOWNLOAD_PATH,
  LINKED_IN_URL,
} from './constants';
import { ABOUT_ME, BECOMING_A_SOFTWARE_ENGINEER } from './content';

const About = forwardRef((_, ref) => {
  const handleOpenLinkedInClick = useCallback(() => {
    window.open(LINKED_IN_URL, '_blank');
  }, []);

  const handleDownloadCVClick = useCallback(() => {
    const aElement = document.createElement('a');
    aElement.setAttribute('download', CV_DOWNLOAD_FILE_NAME);
    aElement.href = CV_DOWNLOAD_PATH;
    aElement.setAttribute('target', '_blank');
    aElement.click();
    URL.revokeObjectURL(CV_DOWNLOAD_PATH);
  }, []);

  return (
    <Box bgcolor="grey.100">
      <Container maxWidth="lg">
        <Box
          ref={ref}
          display="flex"
          justifyContent="center"
          alignItems="center"
          paddingTop={16}
          paddingBottom={8}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={2}
          >
            <Avatar
              alt="Joren Saey"
              src={ProfileImage}
              sx={{ width: 164, height: 164 }}
            />
            <Box display="flex" justifyContent="center">
              <Tooltip title="LinkedIn" placement="bottom">
                <IconButton
                  aria-label="Open LinkedIn"
                  size="small"
                  onClick={handleOpenLinkedInClick}
                  sx={{ mr: 1 }}
                >
                  <LinkedIn />
                </IconButton>
              </Tooltip>
              <Tooltip title="Download CV" placement="bottom">
                <IconButton
                  aria-label="Download CV"
                  size="small"
                  onClick={handleDownloadCVClick}
                >
                  <SaveAlt />
                </IconButton>
              </Tooltip>
            </Box>
            <Typography variant="h2">Hello, I'm Joren</Typography>
          </Box>
        </Box>
        <Box component="section" paddingBottom={8}>
          <Grid container spacing={3}>
            <CardItem
              collapsible
              width={4}
              minMediaWidth={6}
              title="About me"
              body={ABOUT_ME}
            />
            <CardItem
              collapsible
              width={8}
              minMediaWidth={6}
              title="Becoming a software engineer"
              body={BECOMING_A_SOFTWARE_ENGINEER}
            />
          </Grid>
        </Box>
      </Container>
    </Box>
  );
});

export default About;
