export const ABOUT_ME = (
  <>
    Hi, I'm Joren Saey. I currently live in rural Oudegem near Dendermonde
    together with my family. I have 2 little daughters, a girlfriend and a cat.
    They are my motivation for going through life and an infinite source of
    support and love.
    <br />
    <br />
    When I'm not spending time with my family, you can find me walking, cycling
    or just sitting outside, thinking about life. I'm interested in the nature
    of things and also curious about psychology. I like staying active to
    compensate for a mostly sitting-down job. My activities will vary based on
    what motivates me the most at that time. Therefor I don't identify with a
    fixed hobby.
    <br />
    <br />
    When it comes down to my professional life, software engineering is more
    than just my job. It's one of the things in life I can find intrinsic
    motivation for. I like creating solutions and have an eye for detail. I
    can't count the number of times I've started on a personal project just to
    become distracted by a new way of doing something and trying that out in a
    different project.
  </>
);

export const BECOMING_A_SOFTWARE_ENGINEER = (
  <>
    I'm a bit of a late bloomer when it comes down to my interest in software
    development. In my childhood, I did not know what I wanted to become. My
    father "did something with computers" and at that time I didn't give it a
    second thought. He was building, fixing and selling computers and
    accessories, which by now became a dying branch. I did well in school and
    went through the Science and Mathematics program in high school, finishing
    top of the class. In complete fairness, I got a lot of my points for
    studying classes like History or Geography.
    <br />
    <br />
    Because of my score, I was recommended to study Applied Science And
    Engineering in college and started my first year at Hogeschool Gent. It was
    then that I wrote my first lines of Java code. I fell in love with learning
    the language, algorithms and design patterns, but also with the immediate
    feedback you got when compiling and running it on the computer. It was also
    then that I noticed a lack of interest in my other subjects and after the
    final exam period of that year, I decided to switch it up and start my
    bachelor's in Applied Informatics instead.
    <br />
    <br />
    This was a better match for me. I finally did something I felt engaged in. A
    whole new world opened up for me as I could build on the object-oriented
    design patterns in Java and .NET, but also got my first touchpoint with
    JavaScript. In my final year, I fell in love with JavaScript as I learned
    Angular and NodeJS during classes and React / React Native during my
    internship at Codifly. Now I am a full-stack developer using mostly
    JavaScript. Fun fact: Since React Native was just released at that time, I
    am part of the first batch of senior React Native developers.
    <br />
    <br />
    For a more complete (but non-exhaustive) view of my competencies, you can
    check out the "Technologies" section for some practical hard skills. You can
    also take a look at the "Experience" section to get an understanding of my
    professional trajectory. Lastly, you can check the "Projects" section to
    learn what I've worked on.
  </>
);
