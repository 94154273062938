import { FormValues } from './types';

export const sendMail = async (
  { email, body }: FormValues,
  token: string | null,
): Promise<void> => {
  if (!token) {
    throw new Error('Missing token');
  }

  const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/email`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      email,
      body,
    }),
  });

  if (response.status !== 200) {
    throw new Error('Request failed');
  }
};
